import { Link, routes } from "@redwoodjs/router"
import { useAuth } from "@redwoodjs/auth"

const AppLayout = ({ children }) => {
  const { isAuthenticated, currentUser, logIn, logOut, signUp } = useAuth()
  console.log(currentUser)
  return <>
    <div className="bg-gray-900 text-gray-100 font-bold px-2 py-1">
      <Link to={routes.home()}>Humans</Link>
      { isAuthenticated &&
        <>
          {currentUser.email}
          <Link>Log Out</Link>
        </>
      }
      { !isAuthenticated &&
        <>
          <Link to={routes.login()}>Log In</Link>
          <Link to={routes.signup()}>Sign Up</Link>
        </>
      }
    </div>
    {children}
  </>
}

export default AppLayout
