// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Private, Router, Route } from '@redwoodjs/router'
import HumansLayout from 'src/layouts/HumansLayout'
import AppLayout from 'src/layouts/AppLayout'

const Routes = () => {
  return (
    <Router>
      <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
      <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
      <Set wrap={HumansLayout}>
        <Route path="/humans/new" page={HumanNewHumanPage} name="newHuman" />
        <Route path="/humans/{id:Int}/edit" page={HumanEditHumanPage} name="editHuman" />
        <Route path="/humans/{id:Int}" page={HumanHumanPage} name="human" />
        <Route path="/humans" page={HumanHumansPage} name="humans" />
      </Set>
      <Private wrap={AppLayout} unauthenticated="home">
        <Route path="/" page={HumansPage} name="home" />
        <Route path="/human/{id:Int}/{slug}" page={HumanPage} name="human" />
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/signup" page={SignupPage} name="signup" />
      </Private>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
